import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
  Typography,
} from '@mui/material';

import { getCallLogs } from '../../API';
import { useGlobalState } from '../../store';

import { API_URL } from '../../utils';
import moment from 'moment';

const defauleParams = {
  page: 0,
  status: null,
  sort: null,
  tradieId: null,
  diyerId: null,
};

const CallList = () => {
  const [total, setTotal] = useState(0);
  const [calls, setCalls] = useState([]);
  const [page, setPage] = useState(0);
  const { updateNotification } = useGlobalState();

  useEffect(() => {
    getRecord();
  }, []);

  const getRecord = async (params = defauleParams) => {
    const { data, message, error } = await getCallLogs(params);

    if (error) {
      return updateNotification({
        type: 'error',
        title: 'Get Calls.',
        message,
      });
    }

    setTotal(data.total);
    setCalls(calls.concat(data.data));
  };

  const fetchMoreData = async () => {
    const params = {
      page: page + 1,
      status: null,
      sort: null,
      tradieId: null,
      diyerId: null,
    };

    setPage(page + 1);

    getRecord(params);
  };

  return (
    <div
      className="bg-gray-101 h-full gap-2.5 flex flex-col p-4 lg:p-8 gap8 "
      style={{ borderRadius: '15px 15px 0 0' }}>
      <InfiniteScroll
        style={{ overflow: 'hidden ' }}
        dataLength={calls.length}
        next={fetchMoreData}
        hasMore={total > calls.length}
        loader={
          <div className="text-center h-full w-full flex justify-center items-center">
            <CircularProgress />
          </div>
        }>
        {calls.map((call, index) => {
          return (
            <div
              className="mt-2 mb-0 pb-5 pt-5 lg:rounded-lg hover:cursor-pointer w-full flex justify-between items-center"
              key={call.id}
              style={{ borderBottom: '1px solid rgb(242, 242, 242)' }}>
              <div className="w-full">
                <div className="diyer flex items-center">
                  <div
                    className="font-bold flex justify-center items-center"
                    style={{ flexDirection: 'column' }}>
                    <Avatar
                      className="mb-2"
                      key={call.intents?.id}
                      src={`${API_URL}/file/${call.callUsersSender?.id}/profile-image/${call.callUsersSender?.usersProfile?.image}`}
                    />
                    <Chip
                      label={`${
                        call.callUsersSender?.usersTypeId === 1
                          ? 'DIYER'
                          : 'TRADIE'
                      }`}
                    />
                  </div>

                  <div className="ml-5">
                    <div className="font-bold">
                      {call.callUsersSender?.usersProfile?.firstName}{' '}
                      {call.callUsersSender?.usersProfile?.lastName}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                {call.callUsersReceiver ? (
                  <div className="diyer flex items-center">
                    <div
                      className="font-bold flex justify-center items-center"
                      style={{ flexDirection: 'column' }}>
                      <Avatar
                        className="mb-2"
                        key={call.tradieId}
                        src={`${API_URL}/file/${call.callUsersReceiver?.id}/profile-image/${call.callUsersReceiver?.usersProfile?.image}`}
                      />
                      <Chip
                        label={`${
                          call.callUsersReceiver?.usersTypeId === 1
                            ? 'DIYER'
                            : 'TRADIE'
                        }`}
                      />
                    </div>

                    <div className="ml-5">
                      <div className="font-bold">
                        {call.callUsersReceiver?.usersProfile?.firstName}{' '}
                        {call.callUsersReceiver?.usersProfile?.lastName}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="diyer flex items-center">No Answer</div>
                )}
              </div>

              <div className="w-full">{call.callJobs?.title}</div>

              <div className="w-full">
                {moment(call.createdAt).format('DD/MM/YYYY')}
              </div>

              {/* <div className="w-full">
                <Accordion
                  sx={{
                    border: 'none !important',
                    boxShadow: 'none !important',
                  }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography>Stripe Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <pre style={{ width: '200px', fontSize: '12px' }}>
                      {JSON.stringify(payment.paymentDetails, null, 2)}
                    </pre>
                  </AccordionDetails>
                </Accordion>
              </div> */}
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default CallList;
