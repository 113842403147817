import { Tabs, Tab, styled } from '@mui/material';
import React from 'react';

const StyledTab = styled(Tab)`
  flex: 1;
  &.MuiTab-root {
    font-family: Roboto;
    font-size: 1.2rem;
    line-height: 18.75px;
    font-weight: 500;
    text-transform: none;
    color: #131313;
    opacity: 0.4;
  }
  &.Mui-selected {
    opacity: 1;
  }
  &.MuiDivider-root {
    background-color: transparent;
  }
  height: 3.75rem;
`;

function CustomTabs({ value, handleChange, options, className }) {
  const onChange = (e, newValue) => {
    handleChange(newValue);
  };

  return (
    <Tabs
      value={value}
      onChange={onChange}
      className={className}
      classes={{
        flexContainer: className,
      }}>
      {options.map((item, index) => (
        <StyledTab
          label={item.label}
          key={index}
          value={item.value}
          className="px-2 lg:px-[3rem]"
        />
      ))}
    </Tabs>
  );
}

export default CustomTabs;
